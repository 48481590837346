<template>
  <div class="d-flex flex-column">
    <div class='d-flex flex-row justify-space-around align-center'>
      <v-btn
        color="accent"
        @click="generateData()"
      >
        Generate data
      </v-btn>
      <v-slider
        class="width300"
        min="0"
        max="100"
        v-model="lightness"
        thumb-size="20"
        :label="`Lightness ${lightness} %`"
      ></v-slider>
      <v-slider
        class="width300"
        min="0"
        max="100"
        v-model="saturation"
        thumb-size="20"
        :label="`Saturation ${saturation} %`"
      ></v-slider>
    </div>
    <div class="d-flex flex-column mb-6">
      <div
        class="d-flex flex-row align-center ml-4 mr-4"
        v-for="(s,i) in sizes"
        :key="i"
      >
        <span>{{s}}</span>

        <user-ref
          class="ma-2"
          v-for="(_, i) in users"
          :key="i+'xsmall'"
          :id="_.id"
          :saturation="saturation"
          :lightness="lightness"
          :xsmall="s === 'xsmall'"
          :small="s === 'small'"
          :large="s === 'large'"
          :xlarge="s === 'xlarge'"
        />
      </div>
    </div>

  </div>

</template>

<script>
import _ from 'lodash'

export default {
  components: {
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  created () {
    this.generateData()
  },
  data () {
    return {
      users: [],
      saturation: 60,
      lightness: 75,
      sizes: ['xsmall', 'small', 'large', 'xlarge']
    }
  },
  methods: {
    generateData () {
      this.users = []

      const names1 = ['Zara', 'Jaxx', 'Jeff', 'Peggy', 'Elaina', 'Shirley', 'Ria', 'Ben', 'Steve']
      const names2 = ['Kirkland', 'Carson', 'Rios', 'Perez', 'Shepard']
      const emails = ['abc.com', 'defg.org', 'hijklmnop.fr', 'qrstuv.ca']
      const types = [null, 'account-contacts']

      let i
      for (i = 0; i < 20; i++) {
        const name1 = _.sample(names1)
        const name2 = _.sample(names2)

        this.users.push({
          fullName: `${name1} ${name2}`,
          email: `${name1}.${name2}@${_.sample(emails)}`,
          type: _.sample(types)
        })
      }
    }
  }

}
</script>

<style lang="stylus" scoped>
.width300
  max-width 350px
</style>
